/* This example requires Tailwind CSS v2.0+ */
import PlugConnect from '@Psychedelic/plug-connect';
import { BadgeCheckIcon } from '@heroicons/react/outline';

const shortPrincipal = (principal) => `${principal.substr(principal.length-3, principal.length)}`
const startPrincipal=(principal)=>`${principal.substr(0, 5)}`

const messages = [
  {
    id: 1,
    subject: '• The plug wallet you connect here will be the wallet we send the CF token to once its minted',
  },
  {
    id: 2,
    subject: "• This will be a soulbound NFT, so you won't be able to transfer it to another wallet"
  },
  {
    id: 3,
    subject: "• The registered wallet will be the only wallet that can edit this project once it's submittted"
  },
  // More messages...
]



export default function Example(props) {
  
  if (props.principal !== undefined){
    return (
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-0 max-w-4xl mx-auto mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-4xl mx-auto py-24 px-4 sm:py-16 sm:px-6 lg:px-8">
          <h1 className="text-5xl tracking-tight text-gray-400 text-center sm:text-3xl lg:text-3xl">Step 1: Connect a plug wallet to create account</h1>
        </div>
  
        <div className="relative items-center justify-between text-center" >
          <p className='text-center text-gray-500 pt-8'>
            Wallet ID:  {startPrincipal(props.principal.toText())}  ... {shortPrincipal(props.principal.toText())}
            <BadgeCheckIcon class="w-8 h-8 inline-flex ml-4 mb-2 text-green-400"></BadgeCheckIcon>
          </p>
          {/* <div className='relative items-center justify-between text-center mt-5'>
            <h3 class="text-gray-900 text-sm font-medium truncate">Welcome {shortPrincipal(props.principal.toText())}</h3>
            <BadgeCheckIcon class="w-8 h-8 text-green-400"></BadgeCheckIcon>
          </div> */}
        </div>
        <div className="items-center ml-10 pl-10 align-self mt-10 text-center">
          <ul role="list" className="">
            {messages.map((message) => (
              <li
                key={message.id}
                className="relative bg-white py-2 px-4"
              >
                <div className="flex align-self px-20 justify-between text-justify space-x-1">
                  <div className="min-w-0 px-20 flex-1">
                    <a href="#" className="block px-20 focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm px-20 text-gray-500 truncate">{message.subject}</p>
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )

  }
  
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-0 max-w-4xl mx-auto mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-4xl mx-auto py-24 px-4 sm:py-16 sm:px-6 lg:px-8">
        <h1 className="text-5xl tracking-tight text-gray-400 text-center sm:text-3xl lg:text-3xl">Step 1: Connect a plug wallet to create account</h1>
      </div>

      <div className="relative items-center justify-between text-center" >
        <PlugConnect
          whitelist={[]}
          onConnectCallback={
            async () => props.setPrincipal(await window.ic.plug.agent.getPrincipal())
          }
        />
        {/* <div className='relative items-center justify-between text-center mt-5'>
          <h3 class="text-gray-900 text-sm font-medium truncate">Welcome {shortPrincipal(props.principal.toText())}</h3>
          <BadgeCheckIcon class="w-8 h-8 text-green-400"></BadgeCheckIcon>
        </div> */}
      </div>
      <div className="items-center ml-10 pl-10 align-self mt-10 text-center">
        <ul role="list" className="">
          {messages.map((message) => (
            <li
              key={message.id}
              className="relative bg-white py-2 px-4"
            >
              <div className="flex align-self px-20 justify-between text-justify space-x-1">
                <div className="min-w-0 px-20 flex-1">
                  <a href="#" className="block px-20 focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm px-20 text-gray-500 truncate">{message.subject}</p>
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
