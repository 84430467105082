/* This example requires Tailwind CSS v2.0+ */
import {
    CloudUploadIcon,
    CogIcon,
    LockClosedIcon,
    RefreshIcon,
    ServerIcon,
    ShieldCheckIcon,
  } from '@heroicons/react/outline'

  const features = [
    {
      name: 'Connect canisters',
      description: 'Sign up and register your canisters for analysis.',
      icon: CloudUploadIcon,
    },
    {
      name: 'Measure cycles',
      description: 'We create a canister which will continuously measure you cycle burn rate.',
      icon: LockClosedIcon,
    },
    {
      name: 'Calculate footprint',
      description: 'We use your cycle burn rate and our coefficient to calculate the emissions of your canisters.',
      icon: RefreshIcon,
    },
    {
      name: 'Offset footprint',
      description: 'We use CORSIA eligible, nature-based carbon credits to offset your footprint, meaning you’re directly supporting a climate project that uses selling credits to generate revenue.',
      icon: ShieldCheckIcon,
    },
    {
      name: 'Mint NFT',
      description: 'We mint a soulbound NFT which proves your commitment and send it to a wallet of your choice.',
      icon: CogIcon,
    },
    {
      name: 'Verify offset',
      description: 'We link your NFT to the offset registry so you can see your commitment and understand its significance.',
      icon: ServerIcon,
    },
  ]

  export default function HowitWorks() {
    return (
      <div className="relative bg-white py-10 sm:py-10 lg:py-10">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-base font-semibold uppercase tracking-wider text-rose-200"></h2>
          <p className="mt-2 text-5xl tracking-tight text-gray-400 sm:text-4xl">
            What is the Internet Computer Footprint?
            <div className='inline text-gray-800 '> And How does it work?</div>
          </p>
          
          <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          The Internet Computer Footprint (ICF) token verifies the use of institutional grade carbon credits to offset IC project cycles on-chain
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-rose-200 p-3 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
