/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Founders from '../../components/marketplace/founders'
import Divider from '../../components/marketplace/divider'
import Active from '../../components/marketplace/active'
import HowitWorks from '../../components/marketplace/howitworks'
import Footer from '../../components/marketplace/footer'
import Banner from '../../components/marketplace/banner'
import Wallet from '../../components/marketplace/wallet'
import Process from '../../components/marketplace/process'
import Projects from '../../components/marketplace/projects'
import { Link } from 'react-router-dom'
import Nav2 from "../../components/marketplace/nav2"
import { getProjects } from "../../backend";

const navigation = [
  { name: "Contact", href: "https://medium.com/@orlhut/internet-computer-footprint-5d612eefa1b" },
  { name: 'Litepaper', href: 'https://medium.com/@orlhut/internet-computer-footprint-5d612eefa1b' },
  { name: "About Us", href: "https://medium.com/@orlhut/internet-computer-footprint-5d612eefa1b" },

]

function offsetStatus(status) {
  switch (status) {
    case "Offset":
      return "1";
    default:
      return "2" ;
  }
}


export default function Example({principal,setPrincipal}) {

  const [projects, setProjects] = useState([]);

  async function populateProjects() {
    let prjs = await getProjects();
    setProjects(prjs);
  }

  useEffect(() => {
    populateProjects();
  }, [])

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
        <div className="relative h-full max-w-7xl mx-auto">
        </div>
      </div>
      <div className="relative pb-16 sm:pb-24">
        <Popover>
          <Nav2 principal={principal} setPrincipal={setPrincipal}></Nav2>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <div key={item.name}>
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
                <a
                  href="#"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                >
                  Log in
                </a>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h3 className="text-3xl tracking-tight text-gray-400 sm:text-3xl md:text-3xl">
              <span className="block text-5xl xl:inline ">Offset your</span>{' '}
              <span className="inline text-5xl text-rose-200">smart contracts</span>
            </h3>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-lg md:mt-5 md:text-lg md:max-w-3xl">
              Measure and offset your cycles on the Internet Computer.
            </p>
          </div>
        </main>
        <Process></Process>
        <Projects projects={projects}></Projects>
        <HowitWorks></HowitWorks>
        <Founders></Founders>
        <Footer></Footer>
      </div>
    </div>
  )
}
