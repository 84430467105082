import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import LineChart from '../../../graphs'
import { cumulativeTransactions } from '../../../backend'




const default_profile = {
    name: 'Unknown Project',
    logo: '/icfootprint.svg',
    coverImageUrl:
        // 'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
        'https://images.unsplash.com/photo-1520262494112-9fe481d36ec3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3264&q=80',
    about: "...",
}


function OffsetLabel(props) {
    if (props.status) {
        return (
            <div className="flex pt-4">
                <div className="flex-shrink-0 pt-1 pl-20">
                    <CheckCircleIcon className='text-green-600 h-8 w-8' />
                </div>
            </div>
        )
    }

    return (
        <div className="flex pt-4">
            <div className="flex-shrink-0 pt-1 pl-20">
                <XCircleIcon className='text-red-600 h-8 w-8' />
            </div>
        </div>
    )
}
export default function Presentation(props) {

    let project = {
        ...default_profile,
        ...props.project
    };
    let [query] = useSearchParams();
    const [status, setStatus] = useState(query.get("status") === "offset");

    if (!project) {
        project = {
            bio: "Project description..."
        }
    }

    // Reverse transactions for the graph to be chronological
    let tx = cumulativeTransactions(props.transactions.slice().reverse());
    const data = tx.map((tx) => {
        return {
            x: `${tx.date.getFullYear()}.${tx.date.getMonth()}`,
            y: tx.cycles,
        }
    });

    return (

        // <main className="mx-auto bg-gray-300 mt-1 sm:mt-1">
        //     <div className='grid grid-cols-2 sm:grid-cols-2'>

        //         <div className=" text-center items-right">
        //             {/* <div className='max-w-md mx-auto px-15' onClick={() => setStatus(true)}>
        //                 <img src="/logo.png"></img>
        //                 <OffsetLabel status={status} />
        //             </div>

        //             <p className="mt-3 max-w-md mx-auto mr-10  text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-2xl">
        //                 {project.bio}
        //             </p> */}
        //             <div>
        //                 <div className='mt-4 pl-10 ml-10 '>
        //                     <img className="h-32 w-300 rounded-lg object-cover lg:h-48" src={profile.coverImageUrl} alt="" />
        //                 </div>
        //                 <div className="max-w-5xl items-center mx-auto px-20 mx-20 sm:pl-10 lg:pl-10">
        //                     <div className='rounded-lg w-170'>
        //                         <div className="-mt-12 sm:-mt-16 align-center sm:flex sm:items-end sm:space-x-5">
        //                             <div className="flex pl-10 ml-10">
        //                                 <img
        //                                     className="h-15 w-15 rounded-full ring-4 ring-white sm:h-20 sm:w-20"
        //                                     src={profile.imageUrl}
        //                                     alt=""
        //                                 />
        //                             </div>
        //                         </div>
        //                         <div className="hidden text-center mr-20 pr-20 mb-3 w-170 rounded-lg sm:block 2xl:hidden min-w-0">
        //                             <h1 className="text-xl text-center items-center justify-between text-gray-400 pt-6">
        //                                 {profile.name}
        //                             </h1>
        //                             {/* <div className='inline-flex'>
        //                                 <OffsetLabel status={status} />
        //                             </div> */}
        //                             <div className='mt-8 mb-8 pb-6 text-center'>
        //                                 {profile.about}
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className='items-center text-center pt-10 bg-gray-300 text-left'>
        //             <button
        //                 className='inline-flex items-center justify-center px-10 py-2 border border-transparent text-base font-medium rounded-md bg-black'
        //             >
        //                 <span>
        //                     {/* <LineChart aapl={aapl} chart={chart}/> */}
        //                     <p className='text-green-200 text'>Offset my footprint</p>
        //                     <p className='text-white'>Mint ICF Token</p>
        //                 </span>

        //             </button>
        //         </div>

        //     </div>





        // </main>
        <div className='grid bg-white grid-cols-3 sm:grid-cols-3'>
            <div className="">
                <div className="max-w-sm h-auto mx-20 my-20 rounded-md overflow-hidden shadow-lg">
                    <div className=''>
                        <img className="h-32 w-full rounded-lg object-cover lg:h-48" src={project.coverImageUrl} alt=""
                        />
                    </div>
                    <div className='flex bg-white -mt-10 pb-2'>
                        <img
                            className="rounded-full h-20 w-20 mx-auto ring-4 ring-white"
                            src={project.logo}
                        />

                    </div>

                    <div className="px-6 bg-white py-4">
                        <div className="flex flex-col">
                            <div className="font-bold text-xl pb-3 text-center text-gray-800 hover:text-pink-500 hover:cursor-pointer">{project.name}</div>
                            <p className="text-gray-600 text-sm text-center">{project.bio}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-span-2">
                <div className='my-5 mx-10 bg-white h-full'>
                    <LineChart data={data}/>
                </div>
            </div>
        </div>

    )

}