import { Link } from "react-router-dom"
import { useEffect, useState } from 'react'

// const sample_project = {
//     logo: '/icfootprint.svg',
//     name: "Golden Ticket ...",
//     id: null,
//     bg: "bg-grey-100",
// };

const sample_project={
    background:"/background.png",
    logo:"/question_mark.png",
    name:"Golden Ticket",
    bio:"Project onboarding coming soon!",

}

export default function Projects({ projects }) {
    const [sw, setSw] = useState(0)
    const [isMobile, setIsMobile] = useState(false)


    useEffect(() => {
        setSw(window.innerWidth)
        window.addEventListener("resize", () => {
            setSw(window.innerWidth)
        })

        return () => {
            window.removeEventListener("resize", () => {
                setSw(window.innerWidth)
            })
        }
    }, [])

    useEffect(() => {
        setIsMobile(sw < 768)
    }, [sw])

    const number_of_projects=3;
    let padded_projects = projects;
    if (projects.length < number_of_projects) {
        let tmp = new Array(number_of_projects - projects.length).fill(sample_project)
        padded_projects = padded_projects.concat(tmp);
    }
    return (
        <div className="relative bg-white py-10 sm:py-10 lg:py-10">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="text-base font-semibold uppercase tracking-wider text-rose-200"></h2>
                <p className="mt-2 text-5xl tracking-tight text-gray-400 sm:text-4xl">
                    Active Projects
                </p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                These are the projects that are taking steps to make the IC a green blockchain.
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 lg:grid-cols-3 ">
                        {padded_projects.map((features) => (
                            <Link to={features.id ? `/project/${features.id}` : "#"}>
                                <div className="">
                                    <div className="max-w-sm h-auto mx-10 my-10 rounded-md overflow-hidden shadow-lg">
                                        <div className=''>
                                            <img className="h-32 w-full rounded-lg object-cover lg:h-48" src={features.background} alt=""
                                            />
                                        </div>
                                        <div className='flex bg-white -mt-10 pb-2'>
                                            <img
                                                className="rounded-full h-20 w-20 mx-auto ring-4 ring-white"
                                                src={features.logo}
                                            />

                                        </div>

                                        <div className="px-6 bg-white py-4">
                                            <div className="flex flex-col">
                                                <div className="font-bold text-xl pb-3 text-center text-gray-800 hover:text-pink-500 hover:cursor-pointer">{features.name}</div>
                                                <p className="text-gray-600 text-sm text-center">{features.bio}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
