// import React from 'react';
// import { Line, Utils } from "react-chartjs-2";

// const labels = Utils.months({count: 7});
// const data = {
//   labels: labels,
//   datasets: [{
//     label: 'My First Dataset',
//     data: [65, 59, 80, 81, 56, 55, 40],
//     fill: false,
//     borderColor: 'rgb(75, 192, 192)',
//     tension: 0.1
//   }]
// };
// const config = {
//   type: 'line',
//   data: data,
// };
// const LineChart = () => (
//   <>
//     <Line data={data}/>
//   </>
// )

// export default LineChart


import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
const { faker } = require('@faker-js/faker');

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Offset Canisters',
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  // labels,
  datasets: [
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: 'rgb(255, 99, 132)',
    },
  ],
};

function buildData(dt) {
  return {
    // labels,
    labels: dt.map((d) => d.x),
    datasets: [
      {
        label: 'cycles',
        data: dt.map((d) => d.y),
        borderColor: 'rgb(255, 99, 132)',
      },
    ]
  }
}

export default function LineChart(props) {
  return (

    <div className="mb-5 my-10 mr-10">
      <div className='w-full h-80'>
        <Line options={options} data={buildData(props.data)} />
      </div>
    </div>

  )
}