import {
	BrowserRouter,
	Routes,
	Route,
  } from "react-router-dom";
import Marketplace from "./screens/marketplace";
import Form from "./screens/form";
import Dashboard from "./screens/dashboard";
import Preview from "./screens/preview";
import GoldenTicket from "./screens/goldenticket";
import {useState} from 'react';

export default function App() {
  const [principal, setPrincipal] = useState(undefined);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Marketplace principal={principal} setPrincipal={setPrincipal} />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/form" element={<Form principal={principal} setPrincipal={setPrincipal}/>} />
        <Route path="/project/:id" element={<Preview principal={principal} setPrincipal={setPrincipal}/>} />
        <Route path="/info/golden-tickets" element={<GoldenTicket />} />
      </Routes>
    </BrowserRouter>
  );
}