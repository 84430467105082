export const idlFactory = ({ IDL }) => {
  const List = IDL.Rec();
  const Trie = IDL.Rec();
  const ProjectID = IDL.Text;
  const MemberID = IDL.Principal;
  const Branch = IDL.Record({
    'left' : Trie,
    'size' : IDL.Nat,
    'right' : Trie,
  });
  const Hash = IDL.Nat32;
  const Key = IDL.Record({ 'key' : MemberID, 'hash' : Hash });
  List.fill(IDL.Opt(IDL.Tuple(IDL.Tuple(Key, IDL.Null), List)));
  const AssocList = IDL.Opt(IDL.Tuple(IDL.Tuple(Key, IDL.Null), List));
  const Leaf = IDL.Record({ 'size' : IDL.Nat, 'keyvals' : AssocList });
  Trie.fill(
    IDL.Variant({ 'branch' : Branch, 'leaf' : Leaf, 'empty' : IDL.Null })
  );
  const ProjectState = IDL.Variant({
    'Active' : IDL.Null,
    'Archived' : IDL.Null,
    'Pending' : IDL.Null,
  });
  const ProjectMetadata = IDL.Record({
    'members' : Trie,
    'admin' : MemberID,
    'logo' : IDL.Vec(IDL.Nat8),
    'meta' : IDL.Vec(IDL.Nat8),
    'name' : IDL.Text,
    'state' : ProjectState,
    'last_offset_human' : IDL.Text,
    'total_carbon_offset' : IDL.Nat,
  });
  const CommonError__1 = IDL.Variant({ 'Other' : IDL.Text });
  const Result_1 = IDL.Variant({
    'ok' : ProjectMetadata,
    'err' : CommonError__1,
  });
  const CanisterID = IDL.Principal;
  const Cycles = IDL.Int64;
  const Cycles__1 = IDL.Int64;
  const TransactionType = IDL.Variant({
    'Fix' : IDL.Null,
    'Burn' : IDL.Null,
    'Offset' : IDL.Null,
  });
  const Time = IDL.Int;
  const Transaction = IDL.Record({
    'memo' : IDL.Vec(IDL.Nat8),
    'memoType' : IDL.Text,
    'cycles' : Cycles__1,
    'canister' : CanisterID,
    'txType' : TransactionType,
    'datetime' : Time,
  });
  const Result_3 = IDL.Variant({ 'ok' : Transaction, 'err' : CommonError__1 });
  const Result_6 = IDL.Variant({ 'ok' : IDL.Null, 'err' : CommonError__1 });
  const TokenIdentifier = IDL.Text;
  const AccountIdentifier = IDL.Text;
  const User = IDL.Variant({
    'principal' : IDL.Principal,
    'address' : AccountIdentifier,
  });
  const BalanceRequest = IDL.Record({
    'token' : TokenIdentifier,
    'user' : User,
  });
  const Balance = IDL.Nat;
  const CommonError__2 = IDL.Variant({
    'InvalidToken' : TokenIdentifier,
    'Other' : IDL.Text,
  });
  const BalanceResponse = IDL.Variant({
    'ok' : Balance,
    'err' : CommonError__2,
  });
  const TokenIdentifier__1 = IDL.Text;
  const AccountIdentifier__1 = IDL.Text;
  const CommonError = IDL.Variant({
    'InvalidToken' : TokenIdentifier,
    'Other' : IDL.Text,
  });
  const Result_7 = IDL.Variant({
    'ok' : AccountIdentifier__1,
    'err' : CommonError,
  });
  const Extension = IDL.Text;
  const CanisterMetadata = IDL.Record({ 'project' : ProjectID });
  const PublicProjectMetadata = IDL.Record({
    'members' : IDL.Vec(MemberID),
    'admin' : MemberID,
    'logo' : IDL.Vec(IDL.Nat8),
    'meta' : IDL.Vec(IDL.Nat8),
    'name' : IDL.Text,
    'state' : ProjectState,
    'last_offset_human' : IDL.Text,
    'total_carbon_offset' : IDL.Nat,
  });
  const Result_5 = IDL.Variant({
    'ok' : IDL.Vec(Transaction),
    'err' : CommonError__1,
  });
  const HeaderField = IDL.Tuple(IDL.Text, IDL.Text);
  const HttpRequest = IDL.Record({
    'url' : IDL.Text,
    'method' : IDL.Text,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HeaderField),
  });
  const HttpStreamingCallbackToken = IDL.Record({
    'key' : IDL.Text,
    'sha256' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'index' : IDL.Nat,
    'content_encoding' : IDL.Text,
  });
  const HttpStreamingCallbackResponse = IDL.Record({
    'token' : IDL.Opt(HttpStreamingCallbackToken),
    'body' : IDL.Vec(IDL.Nat8),
  });
  const HttpStreamingStrategy = IDL.Variant({
    'Callback' : IDL.Record({
      'token' : HttpStreamingCallbackToken,
      'callback' : IDL.Func(
          [HttpStreamingCallbackToken],
          [HttpStreamingCallbackResponse],
          ['query'],
        ),
    }),
  });
  const HttpResponse = IDL.Record({
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HeaderField),
    'streaming_strategy' : IDL.Opt(HttpStreamingStrategy),
    'status_code' : IDL.Nat16,
  });
  const Result_4 = IDL.Variant({
    'ok' : IDL.Vec(IDL.Nat8),
    'err' : CommonError,
  });
  const Result_2 = IDL.Variant({
    'ok' : CanisterMetadata,
    'err' : CommonError__1,
  });
  const Balance__1 = IDL.Nat;
  const Result = IDL.Variant({ 'ok' : Balance__1, 'err' : CommonError });
  const Memo = IDL.Vec(IDL.Nat8);
  const SubAccount = IDL.Vec(IDL.Nat8);
  const TransferRequest = IDL.Record({
    'to' : User,
    'token' : TokenIdentifier,
    'notify' : IDL.Bool,
    'from' : User,
    'memo' : Memo,
    'subaccount' : IDL.Opt(SubAccount),
    'amount' : Balance,
  });
  const TransferResponse = IDL.Variant({
    'ok' : Balance,
    'err' : IDL.Variant({
      'CannotNotify' : AccountIdentifier,
      'InsufficientBalance' : IDL.Null,
      'InvalidToken' : TokenIdentifier,
      'Rejected' : IDL.Null,
      'Unauthorized' : AccountIdentifier,
      'Other' : IDL.Text,
    }),
  });
  const ICFootprint = IDL.Service({
    'addMemberToProject' : IDL.Func([ProjectID, MemberID], [Result_1], []),
    'addTransaction' : IDL.Func(
        [ProjectID, CanisterID, Cycles, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_3],
        [],
      ),
    'archiveProject' : IDL.Func([ProjectID], [Result_6], []),
    'balance' : IDL.Func([BalanceRequest], [BalanceResponse], ['query']),
    'bearer' : IDL.Func([TokenIdentifier__1], [Result_7], ['query']),
    'deleteProject' : IDL.Func([ProjectID], [Result_6], []),
    'extensions' : IDL.Func([], [IDL.Vec(Extension)], ['query']),
    'fixupTransaction' : IDL.Func(
        [ProjectID, CanisterID, Cycles, IDL.Text, IDL.Vec(IDL.Nat8)],
        [Result_3],
        [],
      ),
    'getCanister' : IDL.Func(
        [IDL.Principal],
        [IDL.Opt(CanisterMetadata)],
        ['query'],
      ),
    'getID' : IDL.Func([], [IDL.Principal], ['query']),
    'getMinter' : IDL.Func([], [IDL.Principal], ['query']),
    'getProject' : IDL.Func(
        [ProjectID],
        [IDL.Opt(PublicProjectMetadata)],
        ['query'],
      ),
    'getProjectMembers' : IDL.Func([ProjectID], [IDL.Vec(MemberID)], ['query']),
    'getProjects' : IDL.Func([], [IDL.Vec(PublicProjectMetadata)], ['query']),
    'getRegistry' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        ['query'],
      ),
    'getSVG' : IDL.Func([], [IDL.Text], ['query']),
    'getTokens' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Nat8)))],
        ['query'],
      ),
    'getTransactions' : IDL.Func([ProjectID], [Result_5], ['query']),
    'http_request' : IDL.Func([HttpRequest], [HttpResponse], ['query']),
    'metadata' : IDL.Func([TokenIdentifier__1], [Result_4], ['query']),
    'offsetTransaction' : IDL.Func(
        [ProjectID, CanisterID, Cycles, IDL.Nat64],
        [Result_3],
        [],
      ),
    'registerCanister' : IDL.Func([ProjectID, CanisterID], [Result_2], []),
    'registerProject' : IDL.Func(
        [ProjectID, IDL.Vec(IDL.Nat8), IDL.Vec(IDL.Nat8)],
        [Result_1],
        [],
      ),
    'setAdmin' : IDL.Func([IDL.Principal], [], []),
    'setInterfaces' : IDL.Func([IDL.Principal, IDL.Principal], [], []),
    'supply' : IDL.Func([TokenIdentifier__1], [Result], ['query']),
    'transfer' : IDL.Func([TransferRequest], [TransferResponse], []),
    'upgradeSVG' : IDL.Func([], [IDL.Text], []),
  });
  return ICFootprint;
};
export const init = ({ IDL }) => { return [IDL.Opt(IDL.Principal)]; };
