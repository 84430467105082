import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Founders from '../../components/marketplace/founders'
import Divider from '../../components/marketplace/divider'
import Active from '../../components/marketplace/active'
import HowitWorks from '../../components/marketplace/howitworks'
import Footer from '../../components/marketplace/footer'
import Stats2 from '../../components/marketplace/stats2'
import List3 from '../../components/marketplace/list3'
import CTA from '../../components/marketplace/cta'
import Banner from '../../components/marketplace/banner'
import Wallet from '../../components/marketplace/wallet'
import { useParams, Navigate, useSearchParams } from 'react-router-dom'
import Nav2 from "../../components/marketplace/nav2"




import {
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { getProject, registerCanister, getTransactions } from '../../backend'
import Presentation from '../../components/marketplace/presentation'

const Modal = props => {
  if (!props.show) {
    return null
  }

  return (

    <div className='bg-gray-400 text-justify text center'>
      <div className='modal content'>
        <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
          <h4 className='py-6 px-6 lg:px-8 mb-4 text-xl font-medium text-gray-900 dark:text-white'>Modal title</h4>
        </div>
        <div className='modal-body'>
          This is the modal content
        </div>
        <div className='modal-footer'>
          <button onClick={props.onClose}>Close</button>
        </div>

      </div>

    </div>

  )
}

const navigation = [
  { name: "Contact", href: "https://twitter.com/icfootprint" },
  { name: 'Litepaper', href: 'https://medium.com/@orlhut/internet-computer-footprint-5d612eefa1b' },
  { name: "About Us", href: "https://medium.com/@orlhut/internet-computer-footprint-5d612eefa1b" },

]

function OffsetLabel(props) {
  if (props.status) {
    return (
      <div className="flex pt-4">
        <div className="flex-shrink-0 pt-1 pl-20">
          <CheckCircleIcon className='text-green-600 h-8 w-8' />
        </div>
        <div>
          <h4 className="text-lg font-bold pl-3 pt-2">Currently Offsetting</h4>
        </div>
      </div>
    )
  }

  return (
    <div className="flex pt-4">
      <div className="flex-shrink-0 pt-1 pl-20">
        <XCircleIcon className='text-red-600 h-8 w-8' />
      </div>
      <div>
        <h4 className="text-lg font-bold pl-3 pt-2">Not Offsetting</h4>
      </div>
    </div>
  )
}

export default function Example({principal,setPrincipal}) {
  let { id } = useParams();

  const [project, setProject] = useState(undefined);
  const [transactions, setTransactions] = useState([]);

  let [query] = useSearchParams();
  const [status, setStatus] = useState(query.get("status") === "offset");

  async function fetchData() {

    let txs = await getTransactions(id);
    setTransactions(txs);

    let project=await getProject(id);
    setProject(project)
  }

  async function addNewCanister(meta) {
    console.log(await registerCanister(id, meta));
  }

  const [show,setShow]=useState(false);



  useEffect(() => {
    fetchData();
  }, [])

  useEffect(()=>{

    window.scrollTo(0,0)

  },[])


  return (
    <div className="relative bg-white overflow-hidden">
      <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
        <div className="relative h-full max-w-7xl mx-auto">
        </div>
      </div>
      <div className="relative pb-16 sm:pb-24">
        <Popover>
          <Nav2 principal={principal} setPrincipal={setPrincipal}></Nav2>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <a
                  href="#"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                >
                  Log in
                </a>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <Presentation project={project} registerCanister={addNewCanister} transactions={transactions}></Presentation>
        <List3 transactions={transactions} refresh={fetchData} project_name={project?.name} ></List3>
        <Footer></Footer>
      </div>
      <div>
       <Modal onClose={()=>setShow(false)}show={show}/>
      </div>
    </div>
  )
}
