/* This example requires Tailwind CSS v2.0+ */
import {
  ChevronRightIcon,
  ViewGridAddIcon,
  CalculatorIcon,
  GiftIcon

} from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const features = [
  {
    name: 'Connect canisters for cycle analysis',
    icon: ViewGridAddIcon,
  },
  {
    name: "Calculate your project's carbon footprint",
    icon: CalculatorIcon,
  },
  {
    name: 'Offset your footprint and mint an NFT to prove it',
    icon: GiftIcon,
  },
]

export default function Process() {
  const [sw, setSw] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setSw(window.innerWidth)
    window.addEventListener("resize", () => {
      setSw(window.innerWidth)
    })

    return () => {
      window.removeEventListener("resize", () => {
        setSw(window.innerWidth)
      })
    }
  }, [])

  useEffect(() => {
    setIsMobile(sw < 768)
  }, [sw])

  return (
    <div className="relative bg-white py-10 sm:py-10 lg:py-10">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-4">
          <div className="grid grid-cols-4 gap-8 sm:grid-cols-4 lg:grid-cols-4" style={isMobile ? {display: "block"} : {}}>
            <div className="mt-8 lg:mt-0 lg:flex-shrink-0">
              <div className={`inline-flex rounded-md ${isMobile ? "py-8" : "pl-20 pt-16"}`}>
                <Link to="/form"
                  className="inline-flex items-center text-2xl justify-center px-10 py-1 border border-transparent text-base font-medium rounded-md text-rose-200 bg-black hover:bg-rose-300"
                >Start</Link>
              </div>
            </div>
            {features.map((feature) => (
              <div key={feature.name} className="pt-5">
                <div className="flow-root rounded-lg px-6 pb-8">
                  <div>
                    <div>
                      <span className={`inline-flex items-center ${!isMobile && "pl-20"} rounded-md p-3`}>
                        <feature.icon className="h-6 w-6 text-grey-600" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="text-sm font-medium tracking-tight text-gray-900 inline-flex">
                      {!isMobile && <ChevronRightIcon className='w-6 h-8 mr-10'></ChevronRightIcon>}
                      {feature.name}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
