import { Link } from "react-router-dom";

/* This example requires Tailwind CSS v2.0+ */
export default function Founders() {
  return (
    <div>
      <div className="mx-auto py-8 sm:px-6 lg:py-10">
        <p className="mt-2 py-8 text-3xl text-center tracking-tight text-gray-400 sm:text-4xl">
          Founding members
        </p>
        <div className="bg-rose-100 w-full">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="tracking-tight text-gray-400 md:text-xl">
              <span className="block text-xl">As a part of our first crowdfunding round, we minted 75 Golden Tickets NFTs.</span>
              <span className="block text-xl text-gray-800">Redeem a Golden Ticket to become a founding member.*</span>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="https://entrepot.app/marketplace/icfootprint"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-rose-200 bg-black hover:bg-rose-300"
                >
                  Buy Golden Ticket
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-rose-100 w-9xl">
          <div className="max-w-7xl mx-auto items-center justify-between">
            <div className="text-sm pl-8 pb-5">
              <span>
              *our project is still in testing. We will notify the IC community when we are ready to start processing projects, starting with the 75 Golden Ticket holders.
              You can find out more about how Golden tickets work <Link to="/info/golden-tickets" className="hover:bg-white underline">here</Link>.
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
