/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react';
import { ArrowRightIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { addTransaction, getCanisterIDs, getOffsetAmount, initiateOffsetPayment } from '../../../backend';
import * as x from "@dfinity/principal";



function Status(props) {
  if (props.status == "Burn") {
    return (
      <div className='rounded-full bg-red-300 text-red-700 text-center w-20'>Burn</div>
    )
  }
  if (props.status == "Offset") {
    return (
      <div className='rounded-full bg-green-300 text-green-700 text-center w-20'>Offset</div>
    )
  }
  if (props.status == "Fix") {
    return (
      <div className='rounded-full bg-orange-300 text-orange-700 text-center w-20'>Fix</div>
    )
  }
}

// TODO: pull this out into the backend to allow us to tweak it more easily.
const COST_PER_KILO = 0.03;

export default function List3(props) {

  const transactions = props.transactions;
  const canister_ids = ["Select Canister"].concat(getCanisterIDs(transactions));
  const amountToOffset = getOffsetAmount(transactions);

  const amount = Math.max(amountToOffset * COST_PER_KILO, 0);

  const canOffset = amount>0;


  const [showModal, setShowModal] = React.useState(false);

  let newTx_file = React.createRef();
  const [newTx, setNewTx] = useState({
    cycles: 0,
    canister: "",
  });

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setNewTx({
      ...newTx,
      ...{
        [name]: value
      }
    });
  }

  async function onTxSubmit(e) {
    e.preventDefault();

    const file = newTx_file.current?.files[0] ?? {};

    let buffer = await file.arrayBuffer();

    let arrayBuffer = new Uint8Array(buffer);
    var arr = Array.prototype.slice.call(arrayBuffer)
    let tx = {
      ...newTx,
      ...{
        project_name: props.project_name,
        memoType: file.type ?? "",
        memo: arr,
        canister: x.Principal.fromText(newTx.canister),
        cycles: Number(newTx.cycles),
        __file: [file],
      }
    }
    console.log(tx)
    let result = await addTransaction(tx);
    console.log(result);
    setShowModal(false);
    props.refresh();
  }

  function initiateOffset() {
    if (!canOffset) {
      return;
    }
    let tx = {
      project: props.project_name,
      amount,
      cycles: transactions.reduce((acc, tx) => acc + tx.cycles, 0),
    }
    initiateOffsetPayment(tx);
    console.log('go on...')
  }

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8 mb-8">
      <div className="sm:flex sm:items-center 2xl:pt-20 xl:pt-10">
        <div className="sm:flex-auto">
          <h1 className="text-xl pl-2 font-semibold text-gray-900">Transactions ({transactions.length})</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            className='inline-flex items-center text-green-200 justify-center px-2 py-2 border border-transparent text-base font-medium rounded-md bg-black'
            style={canOffset?{} : {backgroundColor:"#9e9d9d",color:"#454444"}}
            onClick={initiateOffset}
          >
            <span>
              <p className='text' >Offset all ({amount.toPrecision(2)} ICP)</p>
            </span>

          </button>
          <button onClick={() => setShowModal(true)} className='py-2 ml-5 px-2 border-2 border-gray-200 rounded-lg text-gray-500 hover:bg-gray-200'>
            Add Transaction
          </button>
          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-scroll pt-10 rounded-md fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto mt-40 mb-10 bg-white pt-4 rounded-md mx-auto max-w-3xl">
                  {/*content*/}
                  <form className="space-y-8 divide-y px-20 rounded-md mx-10 pt-10 pb-20 divide-gray-200 overflow-scroll">
                    <div className="space-y-8 divide-y divide-gray-200">
                      <div>
                        <div>
                          <h3 className="text-4xl leading-6 text-center pb-6 font-medium text-gray-400">Add Transactions</h3>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label htmlFor="username" className="block pb-1 text-sm font-medium text-gray-400">
                              Canister ID
                            </label>
                            <div className="mt-1 flex sm:mt-0 sm:col-span-2">
                              <select
                                value={newTx.canister}
                                onChange={handleInputChange}
                                id="country"
                                name="canister"
                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                              >
                                {canister_ids.map((id) => {
                                  return (
                                    <option>{id}</option>
                                  )
                                })}
                              </select>
                            </div>

                          </div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-400">
                              Cycles
                            </label>
                            <div className="mt-1 flex shadow-sm">
                              <input
                                onChange={handleInputChange}
                                value={newTx.cycles}
                                type="text"
                                name="cycles"
                                id="username"
                                autoComplete="username"
                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-6 pt-5">
                          <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-400">
                            Screenshot
                          </label>
                          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload a file</span>
                                  {/* <!-- TODO: figure this one out --> */}
                                  <input ref={newTx_file} id="file-upload" name="file-upload" type="file" className="sr-only" />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                            </div>
                          </div>
                        </div>
                        <div className='my-10'>
                          <button className='flex'>
                            <PlusCircleIcon className='text-gray-400 h-5 w-5'></PlusCircleIcon>
                            <div className='text-sm pl-2 inline-flex text-gray-400'>
                              Add a transaction to a registered canister
                            </div>
                          </button>
                          <button className='flex pt-4'>
                            <PlusCircleIcon className='text-gray-400 h-5 w-5'></PlusCircleIcon>
                            <div className='text-sm pl-2 inline-flex text-gray-400'>
                              Add a transaction with a new Canister ID
                            </div>
                          </button>
                        </div>
                        <div className='max-w-4xl mx-auto pt-20 mt-20 bg-white px-10 sm:rounded-lg sm:p-6'>
                          <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            className="bg-white py-2 px-4 mx-10 mr-10 rounded-md text-xl font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            CANCEL
                          </button>

                          <div
                            className="ml-3 inline-flex border-4 border-gray-200 rounded-md mb-5 items-center px-5 py-2 text-center text-xl border border-transparent hover:bg-gray-200 text-base font-medium text-gray-500 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                            onClick={onTxSubmit}
                          >
                            Submit<ArrowRightIcon className="w-10 h-6"></ArrowRightIcon>
                          </div>
                        </div>

                      </div>
                    </div>



                  </form>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-5 pl-4 pr-3 text-left text-md font-semibold text-gray-500 sm:pl-6">
                Date-Time
              </th>
              <th scope="col" className="py-5 pl-4 pr-3 text-left text-md font-semibold text-gray-500 sm:pl-2">
                Cycles
              </th>
              <th scope="col" className="py-5 pl-4 pr-3 text-left text-md font-semibold text-gray-500 sm:pl-2">
                Footprint
              </th>
              <th scope="col" className="py-5 pl-4 pr-3 text-left text-md font-semibold text-gray-500 sm:pl-2">
                Status
              </th>
              <th scope="col" className="py-5 pl-4 pr-3 text-left text-md font-semibold text-gray-500 sm:pl-2">
                Verify
              </th>
              <th scope="col" className="py-5 pl-4 pr-3 text-center text-md font-semibold text-gray-500 sm:pl-2">
                Canister ID
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {transactions.map((person) => (
              <tr key={person.email}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                  {person.date.toLocaleDateString()}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{person.cycles}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{person.footprint}</td>
                <td className="px-3 py-4 text-sm"><Status status={person.status} /></td>
                <td className="px-3 py-4 text-sm text-gray-500">{person.verify}</td>
                <td className="px-3 py-4 text-sm text-center text-gray-500"><a target="_blank" href={`https://${person.canister}.ic0.app`}>{person.canister}</a></td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )

  // <tbody className="bg-white">
  //   {props.canisters.map((canister) => (
  //     <tr key={canister.id}>
  //       <td className="whitespace-nowrap py-7 pl-4 pr-3 border-y border-gray-200 text-sm font-medium text-gray-900 sm:pl-6">
  //         {canister.id}
  //       </td>
  //       <td className="whitespace-nowrap px-3 py-4 border-y border-gray-200 text-sm text-gray-500">{canister.cycles_offset.toString()}/{canister.cycles.toString()}</td>
  //       <td className="whitespace-nowrap px-3 py-4 border-y border-gray-200 text-sm text-gray-500">{canister.carbon_footprint.value.toString()}kg</td>
  //       <td className="whitespace-nowrap px-3 py-4 border-y border-gray-200">
  //         {
  //           canister.is_offset ?
  //             <p className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800`}>offset</p>
  //             :
  //             <p className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-green-800`}>pending</p>
  //         }
  //       </td>
  //     </tr>
  //   ))}
  // </tbody>

}

{/* <div className="mt-1 flex sm:mt-0 sm:col-span-2">
  <select
    id="country"
    name="country"
    className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
  >
    <option>https://kn5ky-6iaaa-aaaai-qbikq-cai.ic0.app</option>
    <option>https://kn5ky-6iaaa-aaaai-qbikq-cai.ic0.app</option>
    <option>https://kn5ky-6iaaa-aaaai-qbikq-cai.ic0.app</option>
  </select>
</div> */}
