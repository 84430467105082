export const idlFactory = ({ IDL }) => {
  const Coefficient = IDL.Float64;
  const CommonError = IDL.Variant({ 'Other' : IDL.Text });
  const Result_1 = IDL.Variant({ 'ok' : Coefficient, 'err' : CommonError });
  const Time = IDL.Int;
  const Meta = IDL.Record({
    'admin' : IDL.Principal,
    'last_updated' : Time,
    'version' : IDL.Float64,
  });
  const Result = IDL.Variant({ 'ok' : IDL.Null, 'err' : CommonError });
  const methodology = IDL.Service({
    'getCoefficient' : IDL.Func([], [Coefficient], ['query']),
    'getFootprint' : IDL.Func([IDL.Int64], [Result_1], ['query']),
    'meta' : IDL.Func([], [Meta], ['query']),
    'setAdmin' : IDL.Func([IDL.Principal], [Result], []),
    'setCoefficient' : IDL.Func([Coefficient], [Result], []),
  });
  return methodology;
};
export const init = ({ IDL }) => { return [IDL.Opt(IDL.Principal)]; };
